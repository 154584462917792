.col_L {
    float: left;
    overflow: hidden;
    width: 230px;
    margin-left: 30px;
}

.box {
    width: 220px;
    overflow: hidden;
    /* padding-top: 27px; */
}
.listBox {
    width: 220px;
    overflow: hidden;
    padding-top: 15px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 12px;
}
.top {
    width: 100%;
    overflow: hidden;
    & h3 {
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
    }
}
.bottomLine {
    width: 100%;
    height: 20px;
    border-bottom: solid 1px #eeeeee;
}
.listItem {
    font-size: 14px;
    line-height: 22px;
    background: url(http://y0.ifengimg.com/a/2013/0930/c_04.png) left center no-repeat;
    padding-left: 12px;
    & h3 {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
        & a {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
