.footer {
    border-top: 1px solid #ccc;
    width: 1000px;
    margin: 0 auto;
}

.foot_link {
    position: relative;
    height: 28px;
    padding-top: 15px;
    overflow: hidden;
    color: #000;
    line-height: 28px;
    font-size: 12px;
}

.list {
    text-align: center;
}

.link {
    color: #000 !important;
    font-size: 12px;
    text-decoration: none;
    padding: 0 7px 0 14px;
    border-right: 1px solid #000;
}

.link:hover {
    text-decoration: underline;
}

.link:first-child {
    padding-left: 0;
}

.link:last-child {
    border: 0;
}

.copyright {
    font-family: Arial, '宋体';
    color: #000;
    height: 28px;
    text-align: center;
    overflow: hidden;
    line-height: 28px;
    & span {
        padding-right: 12px;
        font-size: 12px;
    }
}
.fk_r {
    width: 200px;
    float: right;
    & ul li {
        height: 28px;
        line-height: 28px;
        overflow: hidden;
        float: left;
    }
}
.l_bg001 {
    background: url(http://y1.ifengimg.com/38716b164e0f5e63/2013/0716/nav0416_14.gif) no-repeat left 9px;
    padding-left: 19px;
}
.l_bg002 {
    background: url(http://y3.ifengimg.com/9db17ab793eef980/2013/0403/nav0416_17.gif) no-repeat left 9px;
    padding-left: 17px;
    margin-left: 18px;
}
