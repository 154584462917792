.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    /* width: 660px; */
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 200px;
        & a {
            color: #000;
        }
    }
}
.p_l_box {
    float: left;
    width: 398px;
    margin-right: 2px;
    overflow: hidden;
}
.p_l_01 {
    width: 198px;
    height: 148px;
    position: relative;
    overflow: hidden;
    margin-bottom: 2px;
    margin-right: 1px;
    float: left;
    &:hover {
        & .bg {
            display: block;
        }
        & .pwText {
            display: block;
        }
    }
}
.p_l_02 {
    width: 398px;
    height: 148px;
    position: relative;
    overflow: hidden;
    margin-bottom: 2px;
    &:hover {
        & .bg {
            display: block;
        }
        & .pwText {
            display: block;
        }
    }
}
.bg {
    position: absolute;
    height: 53px;
    left: 0;
    bottom: 0;
    _bottom: -1px;
    background: #000;
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7;
    display: none;
    width: 100%;
}
.pwText {
    position: absolute;
    height: 53px;
    line-height: 53px;
    left: 0;
    bottom: 0;
    _bottom: -1px;
    text-align: center;
    font-weight: bold;
    display: none;
    width: 100%;
    & a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}
.p_m_box {
    float: left;
    width: 398px;
    margin-right: 2px;
    overflow: hidden;
}
.p_l_0b {
    width: 398px;
    height: 298px;
    position: relative;
    overflow: hidden;
    margin-bottom: 2px;
    margin-right: 2px;
    float: left;
    &:hover {
        & .bg {
            display: block;
        }
        & .pwText {
            display: block;
        }
    }
}
.p_r_box {
    float: left;
    width: 198px;
    overflow: hidden;
}
.p_r_01 {
    float: left;
    width: 198px;
    height: 298px;
    position: relative;
    overflow: hidden;
    margin-bottom: 2px;
    &:hover {
        & .bg {
            display: block;
        }
        & .pwText {
            display: block;
        }
    }
}
.space20 {
    width: 100%;
    height: 20px;
}
