.YueShow {
    overflow: hidden;
}
.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 660px;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 100px;
        & a {
            color: #000;
        }
    }
}
.box {
    width: 300px;
    overflow: hidden;
    margin-top: 20px;
    height: 680px;
}
.listbg {
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/88.jpg) no-repeat 58px 8px;
    position: absolute;
    list-style: none;
    background-size: 1px 97%;
    & li {
        width: 300px;
        position: relative;
        line-height: 27px;
        height: 27px;
        _line-height: 25px;
        _height: 25px;
        background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/dian.jpg) no-repeat 55px 8px;
        & a {
            color: #2b2b2b;
            &:hover {
                color: #339933;
            }
        }
    }
}
.current {
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/lv.jpg) no-repeat 55px 8px !important;
    & a {
        color: #339933 !important;
    }
}
.date01 {
    float: left;
    color: #999;
}
.listtit01 {
    /* float: right; */
    margin-left: 25px;
    width: 220px;
    position: absolute;
    right: 0;
}
