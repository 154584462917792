.ad_top {
    width: 1000px;
    margin: 0 auto;
}
.col_n1000 {
    width: 1000px;
    margin: 0 auto;
    height: 38px;
}
.h_searchDiv {
    width: 1000px;
    margin: 0 auto;
    height: 35px;
    padding-top: 25px;
    & .h_theLogo {
        float: left;
        font-size: 12px;
        & a {
            color: #696969;
            text-decoration: none;
            & img {
                width: 161px;
                /* height: 30px; */
            }
        }
    }
}
