.YueHot {
    width: 300px;
    height: 375px;
}
.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 660px;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 200px;
        & a {
            color: #000;
        }
    }
}
.box {
    width: 300px;
    overflow: hidden;
}
.list_01 {
    width: 300px;
    line-height: 29px;
    height: 29px;
    *line-height: 24px;
    *height: 27px;
}
.num {
    float: left;
    padding-right: 26px;
    font-family: Arial;
    font-size: 14px;
}
.song {
    float: left;
    font-size: 14px;
    color: #2b2b2b;
}
.name {
    float: right;
    font-size: 14px;
    color: #999;
}
.hidden {
    display: none;
}
.list_02 {
    width: 300px;
    height: 73px;
    _height: 75px;
}
.num01 {
    float: left;
    color: #339933;
    font-family: Arial;
    font-size: 24px;
    font-style: italic;
    width: 26px;
    height: 23px;
}
.picTxtR {
    width: 258px;
    height: 65px;
    background: #f6f6f6;
    float: left;
    margin-left: 16px;
    _margin-left: 11px;
    cursor: pointer;
    overflow: hidden;
    & img {
        float: left;
        width: 65px;
        height: 65px;
    }
}
.picR01 {
    width: 183px;
    height: 65px;
    float: right;
    position: relative;
    margin-left: 10px;
}
.picR01_01 {
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/0b.jpg) no-repeat 149px 20px;
    position: absolute;
    width: 193px;
    height: 65px;
    & h3 {
        font-family: 微软雅黑;
        font-size: 20px;
        color: #393;
        font-weight: normal;
        & a {
            color: #339933;
        }
    }
    & p {
        color: #999;
        font-size: 14px;
        line-height: 18px;
        padding-top: 18px;
    }
}
.play {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 9px;
    bottom: 20px;
}
