.YueNishizhen {
    margin-top: 19px;
    width: 300px;
    overflow: hidden;
}
.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 660px;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        & a {
            color: #000;
        }
    }
}
.box {
    padding-top: 13px;
}
.top {
    width: 300px;
    overflow: hidden;
    & h3 {
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
    }
}
.bottomLine {
    width: 100%;
    height: 20px;
    border-bottom: solid 1px #eeeeee;
}
.listItem {
    & h3 {
        font-size: 14px;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
        font-weight: normal;
    }
}
