.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 660px;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 100px;
        & a {
            color: #000;
        }
    }
}
.picWrap {
    width: 660px;
    overflow: hidden;
}
.col_l {
    width: 320px;
    overflow: hidden;
    float: left;
}
.col_r {
    width: 340px;
    overflow: hidden;
    float: right;
}
.bigPic {
    float: left;
    margin-top: 20px;
    width: 320px;
    height: 320px;
    background-color: #ccc;
    & .innerWrap {
        width: 320px;
        height: 320px;
        overflow: hidden;
        position: relative;
    }
    &:hover {
        & .icon {
            display: block;
        }
        & .animation {
            bottom: 0px;
            transition: all 0.3s;
        }
    }
}
.icon {
    display: none;
    position: absolute;
    width: 72px;
    height: 72px;
    overflow: hidden;
    top: 117px;
    left: 125px;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/ship.png) no-repeat;
    _background: none;
    _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(enabled=true,sizingMethod=scale,src="http://y2.ifengimg.com/9949a678442334cc/2013/1120/ship.png");
    z-index: 999;
    cursor: pointer;
}
.textWrap {
    width: 320px;
    height: 49px;
    position: relative;
    & .bg {
        background: none repeat scroll 0 0 #000;
        height: 49px;
        left: 0px;
        filter: alpha(opacity=60);
        -moz-opacity: 0.6;
        -khtml-opacity: 0.6;
        opacity: 0.6;
        bottom: 0px;
        width: 320px;
        position: absolute;
    }
    & .text {
        bottom: 0;
        height: 49px;
        width: 310px;
        left: 10px;
        position: absolute;
        line-height: 49px;
        & a {
            color: #fff;
            font-size: 14px;
        }
    }
}
.smallPic {
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    width: 150px;
    height: 150px;
    background-color: #ccc;
    & .innerWrap01 {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: relative;
    }
    &:hover {
        & .icon05 {
            display: block;
        }
        & .animation {
            bottom: 0px;
            transition: all 0.3s;
        }
    }
}
.icon05 {
    display: none;
    position: absolute;
    width: 72px;
    height: 72px;
    overflow: hidden;
    top: 23px;
    left: 40px;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/ship.png) no-repeat;
    _background: none;
    _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(enabled=true,sizingMethod=scale,src="http://y2.ifengimg.com/9949a678442334cc/2013/1120/ship.png");
    z-index: 999;
    cursor: pointer;
}
.textWrap01 {
    width: 150px;
    height: 49px;
    position: relative;
    & .bg01 {
        background: none repeat scroll 0 0 #000;
        height: 39px;
        left: 0px;
        filter: alpha(opacity=60);
        -moz-opacity: 0.6;
        -khtml-opacity: 0.6;
        opacity: 0.6;
        bottom: 0px;
        width: 150px;
        position: absolute;
    }
    & .text01 {
        bottom: 0;
        height: 39px;
        width: 140px;
        left: 10px;
        position: absolute;
        line-height: 39px;
        & a {
            color: #fff;
            font-size: 14px;
        }
    }
}
.iconHidden {
    display: none;
}
.textHidden {
    position: absolute;
    right: 0px;
}
.animation {
    position: absolute;
    bottom: -49px;
    right: 0px;
    transition: all 0.3s;
}
