.sliderContainer {
    width: 660px;
    height: 368px;
    & [ifeng_ui_pc_sliders-arrows_next] {
        position: absolute;
        top: 160px;
        width: 30px;
        height: 66px;
        background: url(http://y2.ifengimg.com/a/2013/1213/right2.png) no-repeat;
        z-index: 999;
        &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
            background: url(http://y2.ifengimg.com/a/2013/1213/right1.png) no-repeat;
        }
    }
    & [ifeng_ui_pc_sliders-arrows_pre] {
        position: absolute;
        top: 160px;
        width: 30px;
        height: 66px;
        background: url(http://y2.ifengimg.com/a/2013/1213/left2.png) no-repeat;
        z-index: 999;
        &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
            background: url(http://y2.ifengimg.com/a/2013/1213/left1.png) no-repeat;
        }
    }
}
.text_mask {
    width: 660px;
    height: 56px;
    line-height: 56px;
    position: absolute;
    top: 313px;
    background: black;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
}
.test {
    width: 647px;
    height: 56px;
    line-height: 56px;
    position: absolute;
    top: 313px;
    padding-left: 13px;
    & a {
        color: #fff;
        font-size: 20px;
        font-family: 微软雅黑;
        &:hover {
            color: #9bff9c;
        }
    }
}
.dot {
    float: left !important;
    width: 11px !important;
    _height: 12px !important;
    height: 11px !important;
    margin-left: 9px !important;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/02.png) no-repeat !important;
    cursor: pointer !important;
}
.current {
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/01.png) no-repeat !important;
    float: left !important;
    width: 11px !important;
    _height: 12px !important;
    height: 11px !important;
    margin-left: 9px !important;
    cursor: pointer !important;
}
[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    right: 5px;
    bottom: 30px !important;
    height: 3px;
    line-height: 3px;
    z-index: 10;
    left: 560px !important;
    text-align: right;
    font-size: 0;
}
