.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 100%;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        & a {
            color: #000;
        }
    }
}
