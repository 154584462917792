.tit04 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    /* width: 660px; */
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 200px;
        & a {
            color: #000;
        }
    }
}
.colLM01 {
    width: 1000px;
    overflow: hidden;
}
.col_L01 {
    width: 320px;
    height: 320px;
    float: left;
    margin-top: 20px;
}
.col_M01 {
    float: left;
    width: 680px;
    overflow: hidden;
}
.bigPic {
    float: left;
    width: 320px;
    height: 320px;
    background-color: #ccc;
    & .innerWrap {
        width: 320px;
        height: 320px;
        overflow: hidden;
        position: relative;
        & .textWrap {
            width: 320px;
            height: 49px;
            position: relative;
            & .bg {
                background: none repeat scroll 0 0 #000;
                height: 49px;
                left: 0px;
                filter: alpha(opacity=60);
                -moz-opacity: 0.6;
                -khtml-opacity: 0.6;
                opacity: 0.6;
                bottom: 0px;
                width: 320px;
                position: absolute;
            }
            & .text {
                bottom: 0;
                height: 49px;
                width: 320px;
                left: 10px;
                position: absolute;
                line-height: 49px;
                & a {
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
    &:hover {
        & .animation {
            bottom: 0px;
            transition: all 0.3s;
        }
    }
}
.smallPic {
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    width: 150px;
    height: 150px;
    background-color: #ccc;
    & .innerWrap01 {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: relative;
        & .textWrap {
            width: 150px;
            height: 49px;
            position: relative;
            & .bg {
                background: none repeat scroll 0 0 #000;
                height: 49px;
                left: 0px;
                filter: alpha(opacity=60);
                -moz-opacity: 0.6;
                -khtml-opacity: 0.6;
                opacity: 0.6;
                bottom: 0px;
                width: 150px;
                position: absolute;
            }
            & .text {
                bottom: 0;
                height: 49px;
                width: 150px;
                left: 10px;
                position: absolute;
                line-height: 49px;
                & a {
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
    &:hover {
        & .animation {
            bottom: 0px;
            transition: all 0.3s;
        }
    }
}

.animation {
    position: absolute;
    bottom: -49px;
    right: 0px;
    transition: all 0.3s;
}
