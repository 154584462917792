.col_wbf {
    width: 100%;
    margin: 0 auto;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/heise.jpg) repeat-x left top;
}
.YueNav {
    width: 1000px;
    height: 38px;
    margin: 0 auto;
}
.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.col_nav {
    height: 38px;
    line-height: 38px;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/heise.jpg) repeat-x;
    overflow: hidden;
}
.li_ts {
    float: left;
    overflow: hidden;
    background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/heise01.jpg) no-repeat left top;
    & a {
        font-size: 14px;
        color: white;
        font-weight: bold;
        background: url(http://y2.ifengimg.com/9949a678442334cc/2013/1120/heise01.jpg) no-repeat right top;
        display: block;
        height: 38px;
        line-height: 38px;
        padding: 0 16px 0 16px;
        float: left;
    }
}
