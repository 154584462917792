.col {
    width: 1000px;
    overflow: hidden;
    margin: 0 auto;
}
.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.col_LM {
    float: left;
    width: 660px;
    margin-top: 25px;
}
.col_R {
    float: right;
    width: 300px;
    margin-top: 25px;
}
.slide {
    width: 660px;
    height: 368px;
}
.colLM {
    width: 660px;
    margin-top: 26px;
    overflow: hidden;
}
.ad_m_1 {
    width: 1000px;
    margin: 30px auto 40px;
}
.colp01 {
    margin-top: 30px;
}
