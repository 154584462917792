.col_L {
    float: left;
    width: 400px;
    overflow: hidden;
}
.title400 {
    border-bottom: 2px solid #2b2b2b;
    height: 39px;
    width: 400px;
    & h3 {
        font-size: 24px;
        font-weight: bold;
        font-family: 微软雅黑;
        width: 100px;
        & a {
            color: #000;
        }
    }
}
.box400 {
    width: 400px;
    margin-top: 21px;
    overflow: hidden;
    & .picTxt01 {
        width: 400px;
        padding-top: 11px;
        _padding-top: 10px;
        height: 65px;
        position: relative;
        & a img {
            float: left;
            margin-right: 15px;
        }
        & h3 {
            font-size: 14px;
            font-weight: bold;
            padding-top: 10px;
            & a {
                font-size: 14px;
                color: #2b2b2b;
                width: 295px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                &:hover {
                    color: #339933;
                }
            }
            & .fullWidth {
                width: 400px;
            }
        }
        & .Function {
            width: 293px;
            height: 20px;
            float: left;
            position: absolute;
            bottom: 0;
            left: 104px;
            & .time {
                float: left;
                color: #999999;
            }
        }
    }
}
